.section_rooms {
    position: relative;    
    .title_component {
         @media (max-width: 939px) {
             .name {
                 font-size: 1.5em;
             }
             .sub_title {
                 font-size: 3.5em;
             }
         }
        
    }
}
.slick-dots {
    bottom: -30px;
    @media (min-width: 768px) {
        display: none !important;
    }
    .slick-active {
        button {
            &:before {
                content: '';
                background: $maincolor;
            }
        }
    }
    li {
        button {
            width: 13px;
            height: 13px;
            &:before {
                content:'';
                width: 13px;
                height: 13px;
                background: transparent;
                border: 2px solid $maincolor;
                opacity: 1;
            }
        }
    }
}
.arrow_rooms {
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 0;
        width: 45%;
        text-align: right;
        @media (max-width: 767px) {
            display: none !important;
        }
        ._prev {
            display: inline-block;
            position: relative;
            width: 60px;
            height: 60px;
            background: $body;
            margin-right: -4px;
            cursor: pointer;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        ._next {
            display: inline-block;
            width: 60px;
            height: 60px;
            position: relative;
            background: $body;
            cursor: pointer;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }
.box_list_rooms {
    position: relative;
    @media (max-width: 767px) {
        padding: 0 15px;
    }
}
.img_list_rooms {
    height: 800px;
    background-size: cover;
    background-position: center;
     @media (min-width: 1200px) and (max-width: 1439px) {
         height: 600px;
     }
      @media (min-width: 768px) and (max-width: 1199px) {
          height: 550px;
      }
      @media (max-width: 767px) {
          height: 250px;
      }
}
.box_detail_rooms {
    position: absolute;
    padding: 80px;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 0;
    width: 45%;
    background: rgba(#FFFDF9, 0.9);
    @media (min-width: 1200px) and (max-width: 1439px) {
        padding: 30px 80px;
    }
    @media (min-width: 768px) and (max-width: 1199px) {
        padding: 20px 40px;
    }
    .detail_room {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 150px;
        @media (max-width: 1199px) {
            -webkit-line-clamp: 4;
        }
    }
    @media (max-width: 767px) {
        position: relative;
        left: inherit;
        right: inherit;
        top: inherit;
        bottom: inherit;
        width:100%;
        padding: 30px 0;
    }
}
.tab_bed_type {
    padding: 40px 20px;
    border-top: 1px solid rgba($secondcolor, 0.2);
    border-bottom: 1px solid rgba($secondcolor, 0.2);
    margin: 50px 0;
     @media (min-width: 1200px) and (max-width: 1439px) {
         padding: 20px;
         margin: 25px 0;
     }
     @media (max-width: 1199px) {
         padding: 20px;
         margin: 20px 0;
     }
    .list_bed {
        display: inline-block;
        img {
            display: inline-block;
        }
        .type_bed {
            display: inline-block;
            font-weight: 300;
            color: $secondcolor;
            margin-left: 20px;
        }
    }
}
.view_more_room {
    margin-top: 20px;
}