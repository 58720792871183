.content_facilities {
    padding-bottom: 70px;
}
.room_amenities {
    .title_other {
        margin-top: 30px;
        font-family: $titlefonts;
        font-size: 1.2em;
        color: $secondcolor;
    }
}
.room_amenities .amenities_group .amenities_list {    
    p {
        position: relative;
        padding-left: 25px;
        .icon {
            position: absolute;
            left: 0;
            top: 1px;
        }
    }
}