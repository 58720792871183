.bht_header {
	.top_menu {
		&.remove {
			.member-bartab {
				@media (min-width: 1200px) and (max-width: 1299px) {}
			}
		}
	}

	.member-bartab {
		@media (min-width: 1200px) and (max-width: 1299px) {}

		@media (min-width: 1600px) {}

		&:before {
			content: '|';
			color: $fontbodylight;
			position: absolute;
			left: -15px;
			top: 3px;
		}

	}

	.dropdown-member {
		position: relative;
		display: inline-block;
		margin-right: 15px;

		@media screen and (min-width: 1200px) {
			top: -3px;
		}

		.btn_member {
			&:focus {
				outline: none;
			}
		}

		.username {
			display: inline-block;
			margin-right: 5px;
			text-transform: capitalize;
		}

		i {
			color: $maincolor;
			padding: 8px;
			border: 1px solid $maincolor;
			border-radius: 50%;
		}

		span {
			color: $body;
		}

		button {
			background: transparent;
			padding: 0;

			p {
				line-height: 1.5em !important;

				a {
					line-height: 1.5em !important;
					padding: 10px 0 !important;
					color: $body !important;
				}

			}
		}

		#collapseMember {
			position: absolute;
			z-index: 20;
			top: 210%;
			right: -9px;
			float: right;
		}

		.member-bar {
			width: 150px;
			float: right;
			text-align: center;
			font-size: 14px;
			background: rgba($body, 0.6);

			&:before {
				content: '';
				position: absolute;
				top: -5px;
				right: 15px;
				width: 0;
				height: 0;
				border-top: 6px solid $body;
				border-right: 6px solid $body;
				border-bottom: 6px solid transparent;
				border-left: 6px solid transparent;
				transform: rotate(-45deg);
			}

			li {
				a {
					color: $secondcolor;
					font-size: 1.4rem;
					text-transform: uppercase;
					letter-spacing: 1px;
					line-height: 3.4rem;

					@media (min-width: 1200px) and (max-width: 1299px) {
						font-size: .8em;
					}
				}
			}
		}

	}
}

@media screen and (max-width: 767px) {
	.bht_header {
		.dropdown-member {
			.member-bar {
				&:before {
					right: inherit;
					left: 15px;
				}
			}
		}

	}
}

@media screen and (min-width: 1200px) {
	.bht_header {
		.language {
			display: inline-block;

			a {
				color: $secondcolor;
				font-family: $titlefonts;
				text-transform: uppercase;
			}
		}
	}
}


@media (min-width: 992px) {
	.bht_header {
		.member-bartab {
			display: inline-block;
		}
	}
}

@media (max-width: 991px) {
	.bht_header {
		.member-bartab {
			position: absolute;
			top: 23%;
			display: inline-block;
		}
	}
}
