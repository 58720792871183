.content_page {
    .box_list_gallery {
        height: 350px;
        margin: 25px 0;
    }
}


.single_room {
    .list_hightlight {
        .img_hightlight {
            height: 250px;
        }
       .detail_hightlight {
           .box_content_hightlight {
               .name_hightlight {
                   font-size: 1.2em;
               }
           }
       }
    }
}

// Gallery Layout
// .accom_gallery {
//     .gallery_layout {
//         margin-top: 30px;
//     }
// }
.gallery_layout {
    ._no_padding_right {
        padding-right: 0;
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;

        li {
            list-style: none;
        }
    }

    .big_image {
        height: 415px;
       @media (min-width: 992px) {
            &.one_image {
                height: 550px;
            }
       }
    }

    .small_img {
        height: 200px;
    }

    .bg_cover {
        background-size: cover;
        background-position: center;
        cursor: pointer;
        position: relative;
        margin-bottom: 15px;
    }

    .last_img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($bgblack, 0.5);

        p {
            span {
                display: inline-block;
                border: 1px solid $body;
                border-radius: 50%;
                padding: 7px;
            }

            text-align: center;
            color: $body;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .hover_image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($bgblack, 0.5);
        opacity: 0;
        @include transition();

        p {
            span {
                display: inline-block;
                border: 1px solid $body;
                border-radius: 50%;
                padding: 7px;
            }

            text-align: center;
            color: $body;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        &:hover {
            @include transition();
            opacity: 1;
        }
    }

    @media screen and (max-width: 767px) {
        ._no_padding_right {
            padding-right: 15px;
        }

        .big_image {
            height: 200px;
        }
    }
}

.accommodation-single {
    .accom_detail {
        padding-top: 30px;

        .custom_nav_tabs {
            text-transform: uppercase;

            .active {
                a {
                    border: 1px solid #ddd;
                    border-bottom: 1px solid $fontbodylight;
                    color: $fontbodylight;
                    background-color: transparent;
                }

            }

            li {
                margin-right: 10px;

                a {
                    border: 1px solid $fontbodylight;
                    background-color: $fontbodylight;
                    color: $fontbodylight;
                }
            }
        }

        .tab-content {
            border: 1px solid #ddd;
            border-top: none;
            padding: 30px 40px;
        }

        .room_description {
            color: $fontbodydark;

            .border_section {
                border-bottom: 1px solid #ddd;
            }

            .room_title {
                .name {
                    h1 {
                        font-weight: bold;
                        text-transform: uppercase;
                        color: $maincolor;

                        span {
                            color: $fontbodylight;
                        }
                    }
                }

                .room_size {
                    padding-top: 5px;

                    h2 {
                        font-size: 16px;

                        span {
                            font-weight: bold;
                            color: $fontbodylight;
                        }
                    }
                }

            }

            .room_detail {
                padding: 30px 0;
            }

            .room_amenities {
                h2 {
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $fontbodylight;
                    padding: 30px 0;

                    span {
                        color: $maincolor;
                    }
                }

                .amenities_group {
                    padding-left: 0;
                    margin-bottom: 0;

                    .amenities_list {
                        list-style: none;
                        padding-bottom: 5px;

                        .icon {
                            padding-right: 15px;

                            img {
                                width: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .other_room {
        padding-top: 50px;

        .other_room_header {
            text-align: center;
            padding-bottom: 30px;

            p {
                font-weight: bold;
                text-transform: uppercase;
                color: $fontbodylight;
                display: inline-block;
                position: relative;

                &::before {
                    content: '';
                    border-bottom: 8px solid $maincolor;
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    right: 0;
                    width: 20%;
                    text-align: center;
                    margin: 0 auto;
                }

                &::after {
                    content: '';
                    border-bottom: 3px solid #f1f1f1;
                    display: block;
                    padding-top: 10px;
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }
    }
}

.accom_detail {
    margin-top: 20px;

    .room_description {
        padding: 0 10px 50px;

        .room_size,
        .room_amenities {
            display: inline-block;
            margin-right: 30px;
            margin-top: 30px;

            h2 {
                font-size: 1.2em;
                font-family: $titlefonts;
                color: $secondcolor;
                span {
                    font-family: $titlefonts;
                    text-transform: uppercase;
                }
            }
        }

        .bed_type {
            display: inline-block;
            font-family: $titlefonts;
            font-size: 1em;
            text-transform: uppercase;
            font-weight: 600;
            margin-top: 30px;

            img {
                filter: invert(100);
                display: inline-block;
                margin: 0 15px;
            }
        }

        
    }

    .room_detail {
        margin: 30px 0;
    }
}

.custom_nav_tabs {
    border-bottom: 1px solid $secondcolor !important;

    li {
        a {
            strong {
                font-size: 1.2em;
                text-transform: uppercase;
                font-family: $titlefonts;
            }
        }
    }

    >li.active>a,
    >li.active>a:focus,
    >li.active>a:hover {
        border: 1px solid $secondcolor !important;
        border-bottom-color: transparent !important;
    }
}

.other_listsingle {
    .list_deviceroom {
        margin-top: 30px;

        .slick-slide {
            padding: 0 10px;
        }

        .slick-dots {
            bottom: -50px;

            .slick-active {
                button {
                    &:before {
                        content: '';
                        background: $maincolor;
                    }
                }
            }

            li {
                button {
                    width: 13px;
                    height: 13px;

                    &:before {
                        content: '';
                        width: 13px;
                        height: 13px;
                        background: transparent;
                        border: 2px solid $maincolor;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .title_section {
        font-size: 1.8em !important;
    }

    .contentlist_room {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        padding: 30px 15px !important;
        font-size: 0.55em;

    }

    .detail_room {
        .sub_detailroom {
            margin: 10px 0 !important;

            .rooms_size {
                margin-right: 20px;

                strong {
                    font-size: 2em;
                }
            }

            .bed_type {
                img {
                    width: 20px;

                    &:last-child {
                        width: 40px;
                    }

                    &.one_item {
                        width: 20px;
                    }

                    &.two_item {
                        width: 40px;
                    }
                }
            }
        }
    }
}

.other_fac {
    .other_single {
        .box_room {
            .bh2_title {
                margin-bottom: 15px;
            }
        }

        .mainbtn {
            a {
                font-size: 1.4em;
            }
        }
    }
}


.room_amenities {
            display: block;

            h2 {
                font-size: 1.2em;
                margin-bottom: 30px;
                text-transform: uppercase;

                span {
                    font-size: 1em;
                }
            }

            .amenities_group {
                .amenities_list {
                    line-height: 2em;

                    .icon {
                        i {
                            color: $maincolor;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }



.custom_nav_tabs>li.active>a,
.custom_nav_tabs>li.active>a:focus,
.custom_nav_tabs>li.active>a:hover {
    border: none !important;
}
.custom_nav_tabs {
    border:none !important;
}

.custom_nav_tabs{
    >li{
        &.active{
            >a {
                padding-left: 10px;
                padding-right: 10px;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: -15px;
                    width: 70px;
                    height: 1px;
                    background: $maincolor;
                    left: 10px;
                }
            }
        }
    }
}