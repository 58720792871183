@import url('https://use.fontawesome.com/releases/v5.0.6/css/all.css');
@import url('https://fonts.googleapis.com/css?family=Cinzel:400,700,900');


/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 300;
  src: local('Kanit Light Italic'), local('Kanit-LightItalic'), url(https://fonts.gstatic.com/s/kanit/v4/nKKS-Go6G5tXcraQI6miZbdhMWJy.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 300;
  src: local('Kanit Light Italic'), local('Kanit-LightItalic'), url(https://fonts.gstatic.com/s/kanit/v4/nKKS-Go6G5tXcraQI6miZaxhMWJy.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 300;
  src: local('Kanit Light Italic'), local('Kanit-LightItalic'), url(https://fonts.gstatic.com/s/kanit/v4/nKKS-Go6G5tXcraQI6miZa1hMWJy.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 300;
  src: local('Kanit Light Italic'), local('Kanit-LightItalic'), url(https://fonts.gstatic.com/s/kanit/v4/nKKS-Go6G5tXcraQI6miZaNhMQ.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 400;
  src: local('Kanit Italic'), local('Kanit-Italic'), url(https://fonts.gstatic.com/s/kanit/v4/nKKX-Go6G5tXcraQKxaAcJxA.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 400;
  src: local('Kanit Italic'), local('Kanit-Italic'), url(https://fonts.gstatic.com/s/kanit/v4/nKKX-Go6G5tXcraQKwyAcJxA.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 400;
  src: local('Kanit Italic'), local('Kanit-Italic'), url(https://fonts.gstatic.com/s/kanit/v4/nKKX-Go6G5tXcraQKwKAcA.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  src: local('Kanit Bold Italic'), local('Kanit-BoldItalic'), url(https://fonts.gstatic.com/s/kanit/v4/nKKS-Go6G5tXcraQI7mlZbdhMWJy.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  src: local('Kanit Bold Italic'), local('Kanit-BoldItalic'), url(https://fonts.gstatic.com/s/kanit/v4/nKKS-Go6G5tXcraQI7mlZa1hMWJy.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  src: local('Kanit Bold Italic'), local('Kanit-BoldItalic'), url(https://fonts.gstatic.com/s/kanit/v4/nKKS-Go6G5tXcraQI7mlZaNhMQ.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  src: local('Kanit Light'), local('Kanit-Light'), url(https://fonts.gstatic.com/s/kanit/v4/nKKU-Go6G5tXcr4-ORWzVaF5NQ.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  src: local('Kanit Light'), local('Kanit-Light'), url(https://fonts.gstatic.com/s/kanit/v4/nKKU-Go6G5tXcr4-ORWpVaF5NQ.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  src: local('Kanit Light'), local('Kanit-Light'), url(https://fonts.gstatic.com/s/kanit/v4/nKKU-Go6G5tXcr4-ORWnVaE.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: local('Kanit Regular'), local('Kanit-Regular'), url(https://fonts.gstatic.com/s/kanit/v4/nKKZ-Go6G5tXcraBGwCYdA.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: local('Kanit Regular'), local('Kanit-Regular'), url(https://fonts.gstatic.com/s/kanit/v4/nKKZ-Go6G5tXcrabGwCYdA.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: local('Kanit Regular'), local('Kanit-Regular'), url(https://fonts.gstatic.com/s/kanit/v4/nKKZ-Go6G5tXcraVGwA.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: local('Kanit Bold'), local('Kanit-Bold'), url(https://fonts.gstatic.com/s/kanit/v4/nKKU-Go6G5tXcr4uPhWzVaF5NQ.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: local('Kanit Bold'), local('Kanit-Bold'), url(https://fonts.gstatic.com/s/kanit/v4/nKKU-Go6G5tXcr4uPhWpVaF5NQ.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: local('Kanit Bold'), local('Kanit-Bold'), url(https://fonts.gstatic.com/s/kanit/v4/nKKU-Go6G5tXcr4uPhWnVaE.woff2) format('woff2');
  unicode-range : U+0E00-0E7F;
}


@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-LightItalic.woff2') format('woff2'),
        url('../fonts/Gotham-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Elegant';
    src: url('../fonts/Elegant.woff2') format('woff2'),
        url('../fonts/Elegant.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BoldItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-MediumItalic.woff2') format('woff2'),
        url('../fonts/Gotham-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-UltraItalic.woff2') format('woff2'),
        url('../fonts/Gotham-UltraItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Black.woff2') format('woff2'),
        url('../fonts/Gotham-Black.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLightItalic.woff2') format('woff2'),
        url('../fonts/Gotham-XLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLight.woff2') format('woff2'),
        url('../fonts/Gotham-XLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Thin.woff2') format('woff2'),
        url('../fonts/Gotham-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-ThinItalic.woff2') format('woff2'),
        url('../fonts/Gotham-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}



@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i&display=swap');
 // font-family: 'Playfair Display', serif;
@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-LightItalic.woff2') format('woff2'),
        url('../fonts/Gotham-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Elegant';
    src: url('../fonts/Elegant.woff2') format('woff2'),
        url('../fonts/Elegant.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BoldItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-MediumItalic.woff2') format('woff2'),
        url('../fonts/Gotham-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-UltraItalic.woff2') format('woff2'),
        url('../fonts/Gotham-UltraItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Black.woff2') format('woff2'),
        url('../fonts/Gotham-Black.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLightItalic.woff2') format('woff2'),
        url('../fonts/Gotham-XLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLight.woff2') format('woff2'),
        url('../fonts/Gotham-XLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Thin.woff2') format('woff2'),
        url('../fonts/Gotham-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-ThinItalic.woff2') format('woff2'),
        url('../fonts/Gotham-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

