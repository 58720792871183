.section_about {
    padding: 70px 0 100px;
    background: $footer;
    position: relative;
    overflow: hidden;
     &:before {
        content: '';
        background-image: url('../images/bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 0;
        width: 10%;
        height: 35%;
        transform: translate3d(-48%,0,0);
        bottom: 50px;
    }
    &:after {
        content: '';
        background-image: url('../images/bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 0;
        top: 30%;
        width: 15%;
        height: 40%;
        transform: translate3d(65%, 0, 0);
    }
    .title_component {
        text-align: center;       
        .name {
            position: relative;            
            &:after {
                margin: 35px auto 0;
            }
        }
    }
    .detail_home_about {
        text-align: center;
    }
    .img_home_about {
        position: relative;
        height: 450px;
        background-size: cover;
        background-position: center;
        margin-top: 70px;
        @media (max-width: 767px) {
            height: 150px;
        }
        .list_icon_about {
            position: absolute;
            top: -30px;
            text-align: center;
            left: 0;
            right: 0;
            ul {
                position: relative;
                z-index: 2;
            }
            .box_list_icon{
                position: relative;
                display: inline-block;
            }
            li {
                position: relative;
                text-align: center;
                width: 60px;
                height: 60px;
                line-height: 50px;
                background: $body;
                box-shadow: 0 0 3px -1px rgba($bgblack, 0.16);
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                &:hover {
                    transition: all 0.3s ease-in-out;
                    box-shadow: 0 0 20px -5px rgba($bgblack, 0.3);
                    img {
                        transform: scale(1.05) translateX(-50%) translateY(-50%);
                        transition: all 0.3s ease-in-out;
                    }
                }
                &.share_about {
                    i {
                        font-size: 1.5em;
                        line-height: 2.5em;
                        color: #b4bb94;
                    }
                }
                a {
                    width: 60px;
                    height: 60px;
                    display: inline-block;
                }
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    transition: all 0.3s ease-in-out;
                }
                &:not(:last-child) {
                    margin-right: 2rem;
                }
            }
        }
    }
}


@mixin round-button {
    cursor: pointer;
    transition: .25s;
    position: absolute;
    border-radius: 50%;
}

#share {
    position: relative;
}

#share:hover {
//   box-shadow: 0 0px 10px -6px gray;
//   color: rgba(30,30,30,1);
  
  #fb {
    left: 0;
  }
}

// .fa-share-alt, .fa-close {
//   top: 9px;
//   left: -1px;
// }

.sm-social {
  @include round-button;
  top: 19%;
  right:0;
  height: 40px;
  width: 40px;
  font-size: 28px;
 box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.sm-social:hover {
    box-shadow: 0 10px 25px -10px;
  
}

.sm-icon {
  top: 6px;
  color: white;
}

#fb {
  background: #3b5998;
}

#twit {
  background: #55acee;
}

#pin {
  background: #bd081c;
}

.fa-close {
  left: 1px;
}
