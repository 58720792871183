.section_gallery {
   padding-top: 70px;
   background: $bggall;
   overflow: hidden;
   margin-bottom: -7px;
   @media (max-width: 767px) {
       padding-bottom: 70px;
   }
   .title_component {
        text-align: center;   
        display: block;    
        .name {
            &:after {
                margin: 35px auto 0;
            }
        }
    }
}
.list_home_gallery {
    .slick-slide {
        @media (max-width: 767px) {
            margin: 0 15px;
        }
    }
}
.box_list_gallery {
    height: 500px;
    position: relative;
    width: 100%;
    overflow: hidden;
    @media (min-width: 768px) and (max-width: 1199px) {
        height: 350px;
    }
    @media (max-width: 767px) {
        height: 300px;
    }
    &:hover {
         &:before {
             opacity: 0;
             transition: all 0.5s ease-in-out;
         }
         &:after {
             opacity: 1;
             transition: all 0.5s ease-in-out;
         }
         .tab_gall {
            transform: translate3d(0, 0, 0);
            transition: all 0.5s ease-in-out;
            p {
                opacity: 1;
                transition: all 0.5s ease-in-out;
            }
         }
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 30%;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(169, 3, 41, 0) 0%, rgba(67, 47, 17, 1) 100%);
        opacity: 1;
        z-index: 1;
        transition: all 0.5s ease-in-out;
    }
    &:after {
        content: '';
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        background: rgba($body, 0.2);
        opacity: 0;
        transition: all 0.5s ease-in-out;
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);        
    }
    .tab_gall {
        position: absolute;
        bottom: 0;
        padding: 30px;
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        transform: translate3d(0, 60px, 0);
        transition: all 0.5s ease-in-out;
        z-index: 2;
        .name_gall {
            font-family: $titlefonts;
            color: $fontbodylight;
            margin-bottom: 15px;
            font-size: 1.5em;
        }
        p {
            color: $fontbodylight;
            text-transform: uppercase;
            opacity: 0;
            transition: all 0.5s ease-in-out;
        }        
    }
    a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
    }
}

.home,
.page-template-template-attraction {
    .box_list_gallery {
        img {
            width: 140%;
        }
    }
}