body {
	font-family: $mainfont;
	font-size: $fsbody;
	line-height: 30px;
//	color: $fontbodydark;
	font-weight: 400;
	@media (max-width: 991px) {
		overflow-x:hidden;
	}
	.close_menu {
		color: $maincolor;
		position: fixed;
		top	: 10px;
		font-size: 1.3em;
		right: 15px;
		visibility: hidden;
		opacity: 0;
		cursor: pointer;
		z-index: 1080;
	}
	&.bodyoverflow {
		overflow: hidden;
		.close_menu {
			opacity: 1;
			visibility: visible;
			transition: all 0.3s ease-in-out;
		}
	}
}
a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
	margin: 0;
	font-weight: 400;
}

.navbar {
	margin: 0;
}
.slick-slider {
	margin-bottom: -8px;
}

.bht_header {
	.picker--focused .picker__day--selected,
	.picker__day--selected,
	.picker__day--selected:hover {
		background: $maincolor;
	}
	.picker__day--today:before {
		border-top: .5em solid $maincolor;
	}
	.picker__day--highlighted {
		border-color: $maincolor;
	}
	.navbar-default {
	.navbar-nav {
		>.active {
			> a {
				color: $secondcolor;
				background-color: transparent;
				&:focus {
					color: $secondcolor;
					background-color: transparent;
					&:hover {
						color: $secondcolor;
						background-color: transparent;
					}
				}
			}
		}
	}
}
}

.btn_viewmore {
	font-family: $titlefonts;
	color: $fontbodylight;
	text-transform: uppercase;
	padding: 13px 40px;
	text-decoration: none;
	background: $maincolor;
	letter-spacing: 1px;
	display: inline-block;
	transition: all .3s ease-in-out;
	&:hover {
		background: darken($maincolor, 5);
		color: $fontbodylight;
		text-decoration: none;
	}
}

//BUTTON
// .bht_btn {
// 	display: inline-block;
// 	font-size: 1em;
// 	font-weight: 500;
// 	color: $fontbodylight;
// 	background-color: $thirdcolor;
// 	padding: 15px 28px;
// 	transition: all 0.3s ease-in-out;
// 	&:hover {
// 		background-color: darken($thirdcolor, 8);
// 		transition: all 0.3s ease-in-out;
// 		color: $fontbodylight;
// 	}
// }


//arrowslider
.b2h_prev {
	position: absolute;
	left: 30px;
	top: 50%;
	z-index: 20;
	cursor: pointer;
	span {
		color: $body;
		font-size: 40px;
		transition: all 0.3s ease-in-out;
	}
	img {
		filter: brightness(250%);
		transition: filter 0.3s ease-in-out;
	}
	&:hover {
		span {
			color: $maincolor;
			transition: all 0.3s ease-in-out;
		}
		img {
		filter: none;
		transition: filter 0.3s ease-in-out;
	}
	}
}
.b2h_next {
	@extend .b2h_prev;
	left: inherit;
	right: 30px;
	transition: all 0.3s ease-in-out;
}

.picker__frame {
	overflow: hidden;
}
.picker {
	position: relative !important;
}


// title
.title_component {
	padding-right: 20px;
	margin-bottom: 35px;
	.sub_title {
		font-family: $designfonts;
		color: $fontstyle;
		font-size: 4em;
		margin-bottom: -25px;
	}
	.name {
		position: relative;
		color: $secondcolor;
		font-family: $titlefonts;
		font-size: 2em;
		display: inline-block;
		text-transform: uppercase;
		&:after {
			content: '';
			width: 60px;
			height: 1px;
			background: $secondcolor;
			display: block;
			margin-top: 35px;
		}
		 img {
                position: absolute;
                left: -50px;
                top: -100%;
            }
	}
}


.picker__holder {
	min-width: 295px !important;
}

@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}


@keyframes fadeOut {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}


@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%);
  }
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

//helper
.pdNosub {
	padding: 70px 0;
}
.pdBig {
	padding: 100px 0;
}
.pdDefult {
	padding: 35px 0;
}
.mgDefault {
	margin: 20px 0;
}
.pdFotter {
	padding: 20px 0 40px;
}

@media (min-width: 992px) {
	._cpr0 {
		padding-right: 0 !important;
	}
	._ma22 {
		margin: 25px 0 !important;
	}
	._setpad-35 {
		padding: 0 35px !important;
	}
	._setmar-35 {
		margin: 0 -35px !important;
	}
	._cpdes {
		padding: 0 !important;
	}
}

