.section_location {
    padding: $default;
    background: $body;
    >.container {
        @media (min-width: 1440px) {
            width: 1430px;
        }
    }
}
.location_home {
    .view_more_location {
        .btn_viewmore {
            color: $secondcolor;
            border: 1px solid $secondcolor;
            background: transparent;
            padding: 8px 25px;
            font-family: $mainfont;
            margin-top: 30px;
            &:hover {
                background: $secondcolor;
                color: $fontbodylight;
            }
        }
    }
}
.attraction_home {
    box-shadow: 4px 3px 20px -6px rgba(0, 0, 0, .3);
    position: relative;
    @media (max-width: 991px) {
        margin-top: 50px;
    }
    .arrow_rooms {
        width: auto;
        left: inherit;
        bottom: 0;
    }
}
.box_list_att {
    padding: 10px 10px 2px;
    .img_attraction {
        height: 410px;
        width: 100%;
        background-position: center;
        background-size: cover;
        @media (max-width: 767px) {
            height: 250px;
        }
    }
    .content_attraction {
        padding: 50px 30px;
        max-height: 390px;
        .detail_att {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 150px;
        }
        .att_name {
            font-family: $titlefonts;
            color: $secondcolor;
            font-size: 1.5em;  
            margin-bottom: 30px;
            text-transform: uppercase;
        }
        .location_att {
            font-size: 1em;
            span {
                color: $secondcolor;                
            }
        }
        .link_more {
            text-transform: uppercase;
            color:$secondcolor;
            display: inline-block;
            margin-top: 30px;
            font-size: 0.9em;
        }
    }
}