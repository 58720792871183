.title_distance {
    margin-top: 50px;
    .title {
        font-family: $titlefonts;
        color: $secondcolor;
        font-size: 1.5em;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
}


//location
.img_attraction {
	height: 450px;
	width: 500px;
	max-width: 100%;
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover; 
}
.icon_img {
	position: absolute;
	left: -42px;
	top: -10px;
	&:before{
    content: "";
    width: 12px;
    height: 1px;
    background: $maincolor;
    position: absolute;
    right: -12px;
    top: 20px;
	}
	img {
		width: 40px;
		height: 40px;
		padding: 8px;
		border-radius: 50%;
		background: $maincolor;
	}
}
.content_attraction {
	.list_attraction {
        margin-top: 50px;
		border-bottom: 1px solid rgba(#ccc, 0.3);
		padding-left: 70px;
		li {
			font-size: 1em;
			line-height: 2em;
			strong {
				font-size: 1.3em;
				display: block;
				margin-bottom: 15px;
            }
            &:first-child {
                margin-bottom: 30px;
            }
        }
        
		&:last-child {
			border-bottom: 0;
        }
        h3 {
            display: inline-block;
            font-size: 1.5em;
            font-family: $titlefonts;
            color:$secondcolor;
           
        }
	}
}
@media screen and (max-width:767px) {
	.content_attraction {
	.list_attraction {
		font-size: 0.85em;
	}
}
}
@media screen and (max-width:991px) {
.img_attraction {
	height: 400px;
	width: 100%;
    max-width: 100%;
        @media (min-width: 769px) {
            margin-bottom: 50px;
        }
	}
}
.map_for_taxi {
    margin-top: 50px;
    a {
        color: $fontbodylight;
        padding: 15px 40px;
        background: $maincolor;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: darken($maincolor, 10);
            transition: all 0.3s ease-in-out;
        }
    }
}
.tab_address {
    padding: 70px 0;
    background: rgba($maincolor, 0.2);
    h3 {
         font-size: 1.5em;
         font-family: $titlefonts;
         color:$secondcolor;
          margin-bottom: 20px;
    }
    .list_address {
        a {
            color: $fontbodydark;
        }
    }
    .social_footer {
        text-align: left;
        .list-inline {
            margin-top: 15px;
            margin-left: -15px;            
        }
    }
    .container {
        position: relative;
    }
}
.contact_form {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: -70px;
        bottom: -70px;
        left: -30px;
        right: -30px;
        background: rgba($maincolor, 0.1);
    }
    .box_contact {
        position: relative;
        z-index: 1;
        .form-control {
            border-radius: 0;     
            border: none;       
        }
        input.form-control {
            height: 45px;
        }
        .group_last {
            margin-bottom: 0;
        }
        .btn_send {
            background: $maincolor;
            color: $fontbodylight;
            text-transform: uppercase;
            border:none;
            font-family: $titlefonts;
            font-size: 0.9em;
            padding: 10px 35px;
        }
    }
}
.location_tab {
    padding: 70px 0;
}
.bht_footer {
    &.footer_contact {
        background: $maincolor;
        padding: 0;
        .copyright {
            margin-top: 0;
            a {
                color: $fontbodylight;
            }
        }
        #goTop {
           color: $fontbodylight;
           .lnr-chevron-up {
               color: $fontbodylight;
               border: 1px solid $fontbodylight;
           }
        }
    }
}
div.wpcf7 .ajax-loader {
    display: none;
}