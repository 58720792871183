.hero_slider {
	position: relative;
	cursor: -webkit-grab;

	.slick-dots {
		bottom: 50px;

		li {
			width: 45px;

			&.slick-active {
				button {
					&:before {
						background-color: transparent;
						border: 1px solid $fontbodylight;
					}
				}
			}

			button {
				width: auto;
				height: auto;
				padding: 0;
				color: transparent;

				&:before {
					color: transparent;
					content: '';
					height: 5px;
					width: 45px;
					background-color: $fontbodylight;
					opacity: 1;
				}
			}
		}
	}
}

.slide1 {
	&::before {
		content: '';
		position: absolute;
		border: 20px solid #fff;
		width: 100%;
		left: 0;
		right: 0;
		z-index: 9;
		height: 100%;
		background-image: url(../images/logo-transparent.png);
		background-size: 50vh;
		background-repeat: no-repeat;
		background-position: right 70px;
		background-position: right 100px;
		opacity: 0.3;
	}

	//	&::after {
	//		content: '';
	//		
	//		position: absolute;
	//		right: 0;
	//		width: 100%;
	//		left: 0;
	//		right: 0;
	//		z-index: 9;
	//		height: 100vh;
	//	}


}

.slick-slide .img--holder {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.hero-slider .img--holder {
	transform: scale(1);
	transition: transform 10s linear;
}

.hero-slider .slide-init .img--holder {
	transform: scale(1);
	transition: transform 0s linear;
}

.slick-active .img--holder {
	transform: scale(1.1);
}

.hero-slider .slick-arrow {
	position: absolute;
	top: 50%;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .20);
	border: 0 none;
	margin-top: -22.5px;
	text-align: center;
	font: 20px/45px FontAwesome;
	color: #FFF;
	z-index: 5;
	transition: background 0.1s;
}

.hero-slider .slick-arrow:focus {
	outline: 0;
}

.hero-slider .slick-arrow:hover {
	display: block;
	background: rgba(0, 0, 0, .60);
	z-index: 99;
}

.hero-slider .NextArrow {
	right: 0px;
}

.hero-slider .PrevArrow {
	left: 0px;
}

.hero-slider .NextArrow:before {
	content: '\f105';
}

.hero-slider .PrevArrow:before {
	content: '\f104';
}

.hero-slider .text--holder {
	position: absolute;
	display: inline-block;
	width: 100%;
	bottom: 0;
	left: 0;
	z-index: 99;
	padding: 0 0 50px 0;

	font-family: garamond, georgia;
	font-size: 38px;
	color: #fff;
	text-shadow: 0 1px 2px rgba(0, 0, 0, .8);
	text-align: center
}

.hero-slider .text--holder h2 {
	font-family: garamond, georgia;
	font-size: 38px;
}

.hero-slider .text--holder p {
	font-family: garamond, georgia;
	font-size: 28px;
}

@media screen and (min-width: 1199px) {
	.hero_slider {
		overflow: hidden;
		height: 100vh;

		.img--holder {
			height: 100vh;
		}
	}
}

@media screen and (max-width: 1199px) {
	.hero_slider {
		height: 650px;

		.img--holder {
			height: 650px;
		}

		&:before {
			content: none;
		}
	}

	.logo_header {
		display: none;
	}

	.navbar-collapse {
		.btn_reservation {
			display: none;
		}
	}
}

@media (max-width: 991px) {
	.hero_slider {
		height: 40vh;

		.img--holder {
			height: 40vh;
		}
	}
}

@media screen and (max-width: 480px) {
	.hero_slider {
		height: 300px;

		.img--holder {
			height: 300px;
		}
	}
}

@media screen and (max-width: 320px) {
	.hero_slider {
		height: 250px;

		.img--holder {
			height: 250px;
		}
	}
}
