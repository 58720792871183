.bht_footer {

	padding: 20px 0;

	@media screen and (min-width: 992px) {
		padding: 40px 0 20px 0;
	}

	color: #888;

	text-align: center;
	background: #48433D;
	position: relative;
	overflow: hidden;

	&:before {
		//		content: '';
		background-image: url('../images/bg.png');
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		left: 0;
		top: 30px;
		width: 20%;
		transform: translate3d(-35%, 0, 0);
		bottom: 30px;
	}

	&:after {
		//		content: '';
		background-image: url('../images/bg.png');
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		right: 0;
		top: 30px;
		width: 20%;
		transform: translate3d(60%, 0, 0);
		bottom: 30px;
	}

	.logo_footer {
		position: relative;
		top: 120px;
		background: #ffffff;
		z-index: 2;
		display: inline-block;
		padding: 10px 60px;

		@media screen and (max-width: 991px) {
			top: 30px;
		}

		img {
			margin: 0 auto;
		}
	}

	.address_footer {
		li {
			line-height: 26px;
		}

		a {
			color: #888;
		}
	}

	.copyright {
		padding: 50px 0 30px 0;


		text-align: left;
		position: relative;
		//		border-top: 1px solid rgba(0, 0, 0, 0.1);

		@media (max-width: 991px) {
			margin-top: -30px;
			margin-bottom: 20px;
			text-align: center;
		}

		a {
			color: #888;
			font-size: 0.8em;
		}

		.career_link {
			@media screen and (min-width: 992px) {
				margin-left: 20px;
			}

			display: inline-block;
			padding-bottom: 0px;
			border-bottom: 1px solid #888;
			line-height: 24px;
			color: #888;
		}
	}

	.copyright_link {
		margin-right: 20px;
	}

	#goTop {
		span {
			display: block;
		}

		position: absolute;
		right: 0;
		top: -25px;
		color: $main_color;
		vertical-align: middle;
		font-size: 0.8em;
		text-transform: uppercase;
		cursor: pointer;

		.lnr-chevron-up {
			color: $main_color;

			text-align: center;
			width: 30px;
			height: 30px;
			display: inline-block;
			margin-left: 5px;
			vertical-align: middle;
			line-height: 28px;
		}
	}
}

.social_footer {
	margin: 30px 0;
	text-align: center;

	ul {
		margin-left: 0;
	}

	li {
		margin-left: 1rem;
		margin-right: 1rem;

		&:not(:first-child) {
			margin-left: 1rem;
		}

		&:not(:last-child) {
			margin-right: 1rem;
		}
	}

	i {
		color: #BEBDBB;
		font-size: 1.5em;
		transition: all 0.3s ease-in-out;

		&:hover {
			color: $main_color;
			transition: all 0.3s ease-in-out;
		}
	}
}
