.wrapper--big {
	display: block;

	@media screen and (min-width: 992px) {

		position: relative;
	}

	.nav {

		padding-left: 100px;

		.nav-ul {
			a {
				padding: 0 15px;
			}
		}
	}
}

span.nav__dropdown-wrapper.priority-nav__wrapper {
	position: absolute;
	right: 0;

	@media screen and (min-width: 1200px) {
		top: -10px;
	}

	@media screen and (min-width: 992px) and (max-width: 1199px) {
	    top: -8px;
    right: -15px;
	}
}

.priority-nav__dropdown {
	position: fixed;
	top: 0;
	bottom: 0;
	background: #000;
	width: 300px;
	padding: 50px 20px;
	right: 0;
	transform: translate3d(100%, 0, 0);
	text-align: center;
	opacity: 0;
	transition: visibility .3s, opacity .3s, transform .3s;

	> li {
		padding: 10px 0;
	}

	&.show {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition-delay: .2s;
	}

	&::after {
		width: 100vw;
		content: '';
		right: 300px;
		background: rgba(0, 0, 0, 0.5);
		height: 100vh;
		z-index: 9;
		top: 0;
		bottom: 0;
		display: block;
		position: fixed;
	}
}

.priority-nav__dropdown {
	z-index: 3;
	background: #6a4829;

	> li {
		list-style: none;
	}
}

button.nav__dropdown-toggle.priority-nav__dropdown-toggle.priority-nav-is-visible {
	width: 30px;
	height: 30px;
	background-image: url('../images/burger-menu.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-color: transparent;
	border: 0;
	right: 20px;
	top: 20px;

	position: relative;

}

.btn-close {
	position: fixed;
	right: 20px;
	z-index: 99999999;
	color: #fff;
	top: 15px;
	font-size: 20px;
}
