h1,
h2,
h3,
h4,
h5 {
	font-family: $title_font;
}

body {
	font-family: $content_font;
}

div {
	&:focus {
		outline: none !important;
	}
}

a {
	text-decoration: none !important;

	&:focus,
	&:hover,
	&:focus-within,
	&:active {
		text-decoration: none !important;
	}

	color: #888;
}

.logo-group {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: 1;

	.img-logo {
		margin: 0 auto;
		max-width: 400px;

		@media screen and (max-width: 991px) {
			max-width: 200px;
		}
	}
}


.woodlands-logo {
	margin: 0 !important;

	border: none !important;

	img {
		margin-bottom: 0 !important;
	}
}

section#about {


	.woodlands_suites {
		&:hover {
			color: #8C6B46;
		}
	}

	.woodlands_resort {
		&:hover {
			color: #96B333;
		}
	}

	background: #fff;
	position: relative;
	overflow: hidden;
	z-index: 1;

	.hotel-group {
		text-align: center;
		margin-top: 100px;

		.logo {
			margin-bottom: 30px;
			max-width: 300px;
		}

		a {
			letter-spacing: 2px;
			text-align: center;
			text-transform: uppercase;
			padding: 15px;
			border-top: 1px solid rgba(136, 136, 136, .28);
			border-bottom: 1px solid rgba(136, 136, 136, .28);
			font-family: 'Cinzel', serif;
			font-size: 16px;
			margin: 10px 20px 30px 20px;
			display: inline-block;
		}
	}

	h1 {
		color: #888;
		font-weight: 100;
	}

	h2 {
		position: relative;
		margin-bottom: 30px;

		&::after {
			margin-top: 30px;
			width: 80px;
			height: 1px;
			background: #000;
			content: '';
			display: block;
		}
	}

	p {
		color: $content_color;
		line-height: 26px;
	}

	.about-content {
		@media screen and (min-width: 1200px) {
			padding-right: 50px;
		}
	}

	.about-cover {
		height: 300px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;

		@media screen and (min-width: 992px) {
			margin-top: -180px;
		}

		@media screen and (max-width: 991px) {
			margin-bottom: 50px;
		}

		&::before {
			content: '';
			z-index: -1;
			width: 240px;
			height: 240px;
			position: absolute;
			left: -40px;
			bottom: -40px;
			background: #eeeeee;
		}
	}
}

section#accommodation {

	background: #fff;
	position: relative;

	a.btn_view_all {
		margin-top: 50px;

		@media screen and (max-width: 991px) {
			display: none;
		}
	}

	.room-slide {
		position: relative;

		.room-content {
			position: relative;

			.set-position-content {
				@media screen and (min-width: 992px) {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: 80%;

				}

				@media screen and (max-width: 991px) {
					position: static;


					padding: 30px;
				}
			}

			background: #2F2E2A;
			height: 700px;

			@media screen and (max-width: 991px) {

				height: auto;
			}

			p {
				line-height: 26px;
				color: #f8f8f8;
			}

			h2 {
				margin-bottom: 30px;
				font-size: 32px;
				line-height: 38px;
				color: #D0C2A8;
			}

			a {
				margin-top: 30px;
				color: #fff;
				display: inline-block;
				padding: 20px 0;
				text-decoration: none;
			}
		}

		.btn-slide-room {
			display: none;

			button {
				border: 0;
				padding: 0 !important;
			}
		}

		.item {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			height: 700px;

			@media screen and (max-width: 991px) {
				height: 350px;
			}
		}
	}
}

section#facilities {
	.item {
		.title-section {
			span {
				color: #888;
			}

			h2 {
				font-size: 24px;
			}

			margin-top: 30px;

			a {
				outline: none;
				color: $main_color;
				text-decoration: none;
				margin-top: 10px;
				display: block;
				position: relative;
				font-size: 14px;
				letter-spacing: 2px;

				&::after {
					margin-left: 30px;
					content: '';
					top: 16px;
					position: absolute;
					height: 1px;
					width: 50px;
					background: $main_color;
				}
			}
		}
	}

	.facilities-list {

		/* the slides */
		.slick-slide {
			margin: 0 15px;
		}

		/* the parent */
		.slick-list {
			margin: 0 -15px;
		}

		.item {
			.facilities-cover {
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				height: 400px;
			}
		}
	}

	a.btn_view_all {


		@media screen and (max-width: 991px) {
			display: none;
		}
	}

}

section#attraction {
	background: #2F2E2A;

	@media screen and (min-width: 1200px) {
		padding: 80px 0 30px;
	}

	.title-section {
		h2 {
			color: #fff;

			&::after {
				margin: 30px auto 0;

				width: 80px;
				height: 1px;
				background: $main_color;
				content: '';
				display: block;
			}
		}
	}

	.detail_att {
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		max-height: 150px;
	}

	.attraction-list {
		.attraction-content {
			background: #fff;
			height: 600px;

			@media screen and (max-width: 991px) {
				height: auto;
			}

			h2 {
				margin-bottom: 30px;
				font-size: 24px;
				line-height: 38px;
				color: #000;
			}

			p {
				line-height: 26px;
				color: $content_color;
			}

			a {
				margin-top: 30px;
				color: $content_color;
				display: inline-block;
				padding: 20px 0;
				text-decoration: none;
			}

			.set-position-content {
				@media screen and (min-width: 992px) {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: 65%;

				}

				@media screen and (max-width: 991px) {
					position: static;


					padding: 30px;
				}
			}
		}

		.item {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			height: 600px;

			@media screen and (max-width: 991px) {
				height: 350px;
			}
		}
	}

	.btn-slide-attraction {
		margin-top: 50px;

		button {
			font-size: 40px;
			border: none;
			color: #fff;
			background: 0;
		}

		.amount-of-attraction {
			color: #fff;
		}

		._next_attraction {
			float: right;
		}
	}
}

.why-book {
	&.-hide-pc {
		@media screen and (min-width: 992px) {
			display: none;

		}

		@media screen and (max-width: 991px) {
			text-align: center;

			.title-box,
			.gift-box {
				display: block;

				background: none;

				p {
					font-size: 16px;
					font-weight: 400;
					color: #000;
					text-align: center;
				}
			}
		}

		position: static;
	}

	@media screen and (min-width: 1199px) {
		bottom: 40px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 1;
		width: 100%;
		text-align: center;
	}

	.title-box {
		display: inline-block;
	}

	.gift-box {
		margin: 0 30px;

		@media screen and (max-width: 991px) {
			margin: 0 !important;
		}

		display: inline-block;
		background-size: cover;
		background-image: url(../images/rectangle41.png);
		background-repeat: no-repeat;
	}

	img {
		position: relative;
		left: 35px;
		top: -2px;
		display: inline-block;
	}

	p {
		line-height: 46px;
		padding: 0 50px;
		top: 5px;
		font-size: 20px;
		color: #fff;

		font-family: 'Cinzel', serif;
		color: #fff;
		display: inline-block;
	}
}

.boxsearch-position {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 200px;

	.container {
		@media screen and (min-width: 1680px) {
			width: 1440px;
		}
	}


	@media screen and (max-width: 991px) {
		position: static;
		margin-top: 15px;
		background: #fff;

		.container {
			padding: 0;
		}
	}

	@media screen and (max-width: 991px) {


		.input-wrapper {
			padding: 10px 20px !important;
		}

	}

	.boxsearch {
		@media screen and (min-width: 992px) {
			box-shadow: 2px 1px 20px 3px rgba(0, 0, 0, 0.25);
		}


		#discountcode {
			//			background-image: url(../images/discount.png);
			//			padding: 35px 30px;
			font-size: 16px;

			@media screen and (max-width: 991px) {
				padding: 0 15px;
			}
		}

		input {
			color: #826442;

			&:focus {
				outline: none;
				border-color: $second_color;

			}

			background-position: 95% center;
			background-color: rgb(255, 255, 255);
			background-repeat: no-repeat;

		}

		z-index: 2;
		background: rgba(255, 255, 255, 0.51);

		color: #8a8a8a;
		position: relative;

		//        outline: 15px solid rgba(87, 62, 99, 0.5);
		@media screen and (max-width: 991) {
			//			padding: 40px 30px;
			position: static;
		}

		span {
			font-size: 13px;
			margin-bottom: 5px;
			display: block;
		}

		.form-control {
			border: 0 !important;
			border-radius: 0px !important;
			height: 45px;
			box-shadow: none;
			padding: 0 15px;

			@media screen and (max-width: 991px) {
				background: #f9f9f9;
			}
		}

		.input-wraper {
			//			padding: 20px 60px 10px;
			margin: 20px 0;


			&.select-hotel {
				select {
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					/* remove default arrow */
					background-image: url(../images/26894.png);
					background-repeat: no-repeat;
					background-position: 95% center;
					/* add custom arrow */
				}
			}

			@media screen and (min-width: 1200px) {
				&.select-hotel {
					padding: 0 0 0 30px;
				}
			}

			@media screen and (min-width: 992px) and (max-width: 1199px) {
				&.select-hotel {
					padding: 10px 0 0 30px;
				}
			}

			@media screen and (max-width: 991px) {
				&.select-hotel {
					padding: 15px 30px !important;
				}
			}

			@media screen and (max-width: 1199px) {
				margin: 0;
				padding: 10px 0;
			}

			input {
				font-size: 16px;
				padding: 0 15px;


			}
		}

		a#btnBook {
			background: #826442;

			border: 0 !important;
			border-radius: 0px;
			color: #fff;
			//			padding: 41px 30px;
			letter-spacing: 1px;
			font-size: 20px;
			font-weight: 100;
			font-family: $title_font;
			width: 100%;
			margin: 20px 0px;
			height: 45px;
			line-height: 32px;

			@media screen and (min-width: 992px) and (max-width: 1199px) {
				margin: 10px 0;
				font-size: 16px;
				line-height: 32px;
			}

			@media screen and (max-width: 991px) {
				padding: 10px 20px;
			}
		}



	}
}

.body_page .bht_footer {
	background: #48433D !important;
}

.slick-dots li button:before {
	border-radius: 50%;
}

.slick-dots {
	bottom: -30px;
}
