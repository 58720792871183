$maincolor: #644324;
$secondcolor: #81520C;
$body: #fff;
$fontbodylight: #fff;
$fontbodydark: #959595;
$fontstyle: rgba(#A8A7A5, 0.3);
$bgblack: #000;
$bggall: #F1EFEB;
$footer :#FFFDF9;
  
$title_font :  'Cinzel', serif;
$content_font : 'Gotham';

//fonts 
$mainfont :  'Gotham';
$titlefonts : 'Cinzel', serif;
$designfonts : 'Elegant', serif;

//fontssize
$fsbody : 16px;
$fstitle : 2em;
$fstitleres: 1.8em;

//padding
$default : 100px 0;
$subdefault : 50px 0;

  

$main_color : #D2C2A1;
$content_color : #888;
$second_color : #cc7c00;
// fonts style 

// font-family: 'Playfair Display', serif;

