.bht_header {
	position: relative;

	.to_section {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 50px;
		height: 45px;
		background: $body;
		color: $secondcolor;
		text-align: center;
		line-height: 56px;
		z-index: 1;
		text-decoration: none;

		&:focus {
			text-decoration: none;
		}

		span {
			font-size: 1.5em;
		}
	}

	.top_menu {
		background: #6a4829;
		padding: 5px 15px;

		@media (max-width: 991px) {
			position: fixed;
			width: 100%;
			left: 0;
			right: 0;
			top: 0;
			z-index: 80;
			box-shadow: 0px 1px 9px -3px rgba($bgblack, 0.18);
			padding-top: 0;
			padding-bottom: 0;
		}

		.logo_site {
			@media (min-width: 992px) and (max-width: 1399px) {
				max-width: 150px;
			}

			@media (max-width: 991px) {
				max-width: 120px;
				float: left;
				display: inline-block;

				a {
					display: inline-block;
				}

				img {
					position: relative;
					top: 3px;
				}
			}
		}

		.nav_right {

			//			display: block;
			@media (min-width: 1600px) and (max-width: 1799px) {
				max-width: 85%;
			}

			@media (min-width: 1400px) and (max-width: 1599px) {
				max-width: 78%;
			}

			@media (min-width: 1200px) and (max-width: 1399px) {
				max-width: 80%;
				padding-top: 10px;
				//				display: flex;
			}

			@media screen and (min-width: 992px) and (max-width: 1199px) {

				max-width: 100%;

				padding-top: 10px;



			}

			@media (max-width: 991px) {
				// position: absolute;
				// left: 0;
				// right: 0;
				// top: 0;
				// width: 100%;
				display: inline-block;
				float: right;
				margin-right: 30px;
			}

			.menu_bar {
				@media (min-width: 1600px) and (max-width: 1799px) {
					max-width: 60%;
				}

				@media (min-width: 1400px) and (max-width: 1599px) {
					max-width: 60%;
				}

				@media (min-width: 1200px) and (max-width: 1399px) {
					max-width: 60%;
					margin-right: 20px;
				}

				@media screen and (min-width: 992px) and (max-width: 1199px) {
					max-width: 50%;
				}

				@media (max-width: 991px) {
					width: 50px;
					// float: right;
				}

				li {
					a {
						font-family: $title_font;
						color: #fff;

						@media (min-width: 992px) and (max-width: 1399px) {
							font-size: 0.85em;
							padding: 0 12px;
						}
					}
				}


			}
		}

		.languaue {
			@media (min-width: 992px) and (max-width: 1399px) {
				margin-right: 12px;
			}

			a {
				@media (min-width: 992px) and (max-width: 1399px) {
					font-size: 0.85em;
				}
			}
		}

		.btn_book {
			.link_book {
				font-family: $titlefonts;
				color: $fontbodylight;
				text-transform: uppercase;
				padding: 15px 50px;
				background: #9e7653;
				background: -moz-linear-gradient(top, #9e7653 0, #644324 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(0, #9e7653), color-stop(100%, #644324));
				background: -webkit-linear-gradient(top, #9e7653 0, #644324 100%);
				background: -o-linear-gradient(top, #9e7653 0, #644324 100%);
				background: -ms-linear-gradient(top, #9e7653 0, #644324 100%);
				background: linear-gradient(to bottom, #9e7653 0, #644324 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9e7653', endColorstr='#644324', GradientType=0);
				letter-spacing: 1px;
				transition: all 0.3s ease-in-out;

				&:hover {
					background: darken($maincolor, 5);
					transition: all 0.3s ease-in-out;
				}

				@media (min-width: 992px) and (max-width: 1399px) {
					font-size: 0.7em;
					    padding: 15px 40px;
				}
			}
		}
	}
}

@media screen and (min-width: 992px) {
	.bht_header {
		> .container-fluid {
			padding-left: 0;
			padding-right: 0;
		}

		.top_menu {
			position: fixed;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			z-index: 50;
			transition: all 0.5s ease-in-out;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.25) 53%, rgba(0, 0, 0, 0) 100%);

			padding: 10px 15px 70px 50px;

			&.remove {
				top: 0;
				transition: all 0.5s ease-in-out;
				box-shadow: 0px 1px 9px -3px rgba($bgblack, 0.18);
				padding: 10px 15px 10px 50px;
				background: rgba(0, 0, 0, 0.7);

				.nav_right {
					padding-top: 0;
					transition: all 0.5s ease-in-out;
				}

				.logo_site {
					width: 130px;
					transition: all 0.5s ease-in-out;
				}
			}

			&.show-menu {
				top: 0;
				z-index: 1040;
				transition: all 0.5s ease-in-out;
			}

			.menu_bar {
				li {
					a {
						font-family: $titlefonts;
						color: $secondcolor;
						padding: 0 15px;
						line-height: 50px;
					}
				}
			}

			&.fadelogo {
				&.show-menu {
					background: rgba(0, 0, 0, 0.7);
					box-shadow: 0px 1px 9px -3px rgba($bgblack, 0.18);
					padding: 10px 15px 10px 50px;

					.nav_right {
						padding-top: 0;
						transition: all 0.5s ease-in-out;
					}

					.logo_site {
						width: 130px;
						transition: all 0.5s ease-in-out;
					}
				}
			}
		}

		.logo_site {
			float: left;
			display: inline-block;
			width: 219px;
			transition: all 0.5s ease-in-out;
		}

		.nav_right {
			@media screen and (min-width: 1200px) {
				float: right;
				padding-right: 60px;
			}

			@media screen and (min-width: 1200px) {
				display: inline-block;
			}

			padding-top: 25px;

			transition: all 0.5s ease-in-out;

			.menu_bar {
				display: inline-block;
				width: 1100px;
				max-width: 80%;

			}

			.languaue {
				display: inline-block;
				margin-right: 40px;

				a {
					color: #fff;
					font-family: $titlefonts;
				}
			}

			.btn_book {
				display: inline-block;

				a {
					display: inline-block;
				}
			}
		}
	}
}
