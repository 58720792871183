.boxsearch-fixed {
	position: absolute;
	bottom: 0;
	opacity: 0;
	display: none;
	width: 100%;
	background: $body;
	font-family: $titlefonts;
	box-shadow: 0 3px 30px -7px rgba(49, 47, 47, 0.25);
	transition: all 0.4s ease-in-out;

	&.opened {
		.overlay_boxsearch {
			@include transition(0.5);
			opacity: 1;
			z-index: 9999;
			overflow-y: auto;
		}

		._btn_close {
			@include transition(0.5);
			opacity: 1;
			z-index: 10000;
		}
	}

	.position {
		z-index: 6;
		position: relative;
		padding: 25px 0;

		.boxsearch-style {

			// span {
			//     color: $fontbodylight;
			//     text-transform: uppercase;
			//     padding: 10px 0;
			//     display: block;
			//     font-weight: 400;
			// }
			.form-control {
				border: none;
				height: auto;
				padding: 0;
				font-weight: 400;
				border-bottom: 1px solid $fontbodydark;
			}

			label {
				margin-bottom: 0;
				color: $bgblack;
				font-weight: 300;
				font-size: .8em;
			}

			#dateci,
			#dateco {
				color: $fontbodydark;
				font-size: 1em;
				text-transform: uppercase;
				height: 35px;
				background-image: url('../images/calendar.png');
				background-position: 96% center;
				background-repeat: no-repeat;
				cursor: pointer;
			}

			input#discountcode {
				background: transparent;
				text-align: left;
				color: $fontbodydark !important;
				font-size: 1em;
				text-decoration: none;
				height: 35px;
			}

			.form-control::-webkit-input-placeholder {
				color: $fontbodydark !important;
			}

			.form-control::-moz-placeholder {
				color: $fontbodydark !important;
			}

			.form-control:-ms-input-placeholder {
				color: $fontbodydark !important;
			}

			.form-control:-moz-placeholder {
				color: $fontbodydark !important;
			}

			.title_boxsearch {
				text-align: right;
				color: $fontbodydark;
				font-size: 1.5em;
				font-weight: bold;
				font-family: $titlefonts;
				margin-right: 15px;
				position: relative;
				top: 10px;

				@media (max-width: 991px) {
					text-align: center;
					margin-bottom: 30px;
				}

				span {
					display: block;
				}
			}
		}

		.title-box {
			position: relative;
			top: 28px;
			display: inline-block;
			padding: 10px;
			background: $maincolor;
			color: $fontbodylight;
			text-transform: uppercase;
		}

		.form-control {
			width: 100%;
			height: 45px;
			padding: 6px 12px;
			background: 0;
			border: 1px solid rgba($fontbodylight, 0.3);
			border-radius: 0;
			box-shadow: none;
		}
	}

	a#btnBook {
		width: 100%;
		background-color: #644324;
		display: block;
		text-align: center;
		height: 60px;
		line-height: 60px;
		text-transform: uppercase;
		color: $fontbodylight;
		font-size: 1.2em;
		font-weight: 600;
		text-decoration: none;
		transition: background 0.3s ease-in-out;
		position: relative;
		top: 6px;

		@media (min-width: 992px) and (max-width: 1199px) {
			font-size: .8em;
		}

		&:hover {
			transition: background 0.3s ease-in-out;
			background: darken($maincolor, 20);
		}
	}

	._btn_close {
		top: 0;
		margin: 30px;
		position: fixed;
		right: 0;
		cursor: pointer;
		opacity: 0;

		span {
			font-size: 30px;
			color: $maincolor;
		}
	}
}

.remove-boxsearch {
	background: 0;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.btn-mobile {
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 99;

	.btn-call-mobile {
		background: #272727;
		padding: 20px;
		text-align: center;

		a {
			color: #fff;
			text-transform: uppercase;
		}
	}

	.btn-book-mobile {
		background: $secondcolor;
		padding: 20px;
		text-align: center;
		color: #fff;
		text-transform: uppercase;
	}
}





@media screen and (min-width: 992px) {
	.btn-mobile {
		display: none;
	}
}

.b2h_loading_rate {
	text-align: center;

	img {
		margin: 0 auto;
	}
}

.picker__day {
	font-weight: 600 !important;
}

.boxsearch-implement-page {
	background-image: url(../images/bg_accom.png);
	padding: 20px;
	color: $maincolor;

	.position {
		z-index: 6;

		.boxsearch-style {
			@media screen and (max-width: 991px) {
				padding: 20px;
			}

			@media screen and (min-width: 992px) {
				padding: 10px 50px 30px;
			}

			span {
				color: $maincolor;
				text-transform: uppercase;
				padding: 10px 0;
				display: block;
				font-size: 12px;
			}

			input#discountcode {
				border: 0;
				background: #333;
				text-align: center;
				color: #fff !important;
			}

			.form-control::-webkit-input-placeholder {
				color: #fff !important;
			}

			.form-control::-moz-placeholder {
				color: #fff !important;
			}

			.form-control:-ms-input-placeholder {
				color: #fff !important;
			}

			.form-control:-moz-placeholder {
				color: #fff !important;
			}
		}

		.title-box {
			position: relative;
			top: 28px;
			display: inline-block;
			padding: 10px;
			background: $secondcolor;
			color: $maincolor;
		}

		.form-control {
			color: #fff !important;
			width: 100%;
			height: 45px;
			padding: 6px 12px;
			background: 0;
			border: 1px solid rgba($maincolor, 0.3);
			border-radius: 0;
			box-shadow: none;
		}
	}

	a#btnBook {
		width: 100%;
		background: $secondcolor;
		display: block;
		text-align: center;
		height: 44px;
		line-height: 45px;
		text-transform: uppercase;
		color: #fff;
		margin-top: 38px;
		font-size: 14px;
		text-decoration: none;
		transition: background 0.3s ease-in-out;

		&:hover {
			background: darken($secondcolor, 10);
			transition: background 0.3s ease-in-out;
		}

		@media screen and (min-width: 1200px) {
			font-size: 18px !important;
		}
	}
}

.promotions-img {
	img {
		width: 100%;
	}
}

.btn_navbook {
	cursor: pointer;
}

.picker__day--outfocus {
	color: rgba($fontbodydark, 0.7) !important;
}


.openMobox {
	overflow: hidden;

	.to_section {
		z-index: -1;
	}

	.boxsearch-fixed {
		position: fixed;
		display: block;
		width: 100%;
		z-index: -1;
		top: 0;
		opacity: 0;
		transition: all 0.3s ease-in-out;
		background: rgba($body, .92);
		z-index: 20001;
		opacity: 1;
		transition: all 0.4s ease-in-out;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		@media (max-width: 991px) {
			width: 100%;
			left: 0;
			right: 0;
			background: $body;
		}

		.position {
			@media (min-width: 992px) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				animation: fadeIn 1s ease-in-out;
			}
		}
	}

	.remove-boxsearch {
		z-index: 5;
	}

	._btn_close {
		opacity: 1;
		z-index: 7;
	}
}

@media (max-width: 991px) {
	.boxsearch-fixed {
		position: fixed;
		width: 100%;
		z-index: -1;
		top: 0;
		opacity: 0;
		transition: all 0.3s ease-in-out;

		.form-control {
			margin-bottom: 15px;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.boxsearch-style {
		max-width: 400px;
	}
}

.tab_bottom {
	position: fixed;
	bottom: 0;
	background: $body;
	left: 0;
	right: 0;
	z-index: 30;
	box-shadow: 0px -1px 9px -3px rgba($bgblack, 0.18);

	.list_mo {
		position: relative;
		width: 100%;
		height: 55px;
		text-align: center;

		.icon_mo {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			color: $maincolor;
			cursor: pointer;

			img {
				max-height: 20px;
				line-height: 20px;
				margin: 0 auto 9px;

			}

			.lnr {
				font-size: 1.3em;
			}

			p {
				text-transform: uppercase;
				font-size: 0.65em;
				line-height: 10px;
			}
		}

		&.link_book {
			background: $maincolor;

			.icon_mo {
				color: $fontbodylight;
			}
		}
	}

	[class*="col-"] {
		padding: 0;
	}
}
.link_book_home {
	@extend .link_book;
	
}
.member_bar {
	margin: 50px 0;
	position: relative;

	&:before {
		content: '';
		width: 80px;
		height: 1px;
		background: $maincolor;
		margin: 0 auto;
		left: 0;
		right: 0;
		bottom: -20px;
		position: absolute;
	}

	a {
		color: $maincolor;
		text-transform: uppercase;

	}
}
