.svg-slogan {

	svg {
		@media screen and (max-width: 991px) {
			width: 90%;
			top: 60%;
		}

		position: absolute;
		width: 60%;
		height: auto;
		box-sizing: border-box;
		top: 75%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9;
	}

	path {
		fill: none;
		stroke: white;
		stroke-width: 2;
		opacity: 1;
	}
}

.st0 {
	fill: none;
	stroke: #fff;
	opacity: 1 !important;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
}

.txt_1 {
	stroke-dasharray: 460;
	stroke-dashoffset: 460;
	animation: brush_460 1s linear forwards 3s;
}

.txt_2 {
	stroke-dasharray: 310;
	stroke-dashoffset: -310;
	animation: brush_-310 1s linear forwards 3.6s;
}

.txt_3 {
	stroke-dasharray: 160;
	stroke-dashoffset: 160;
	animation: brush_160 0.5s linear forwards 4.3s;
}

.txt_4 {
	stroke-dasharray: 160;
	stroke-dashoffset: -160;
	animation: brush_-160 0.5s linear forwards 4.6s;
}

.txt_5 {
	stroke-dasharray: 230;
	stroke-dashoffset: 230;
	animation: brush_230 0.5s linear forwards 5.2s;
}

.txt_6 {
	stroke-dasharray: 110;
	stroke-dashoffset: 110;
	animation: brush_110 0.5s linear forwards 5.6s;
}

.txt_7 {
	stroke-dasharray: 130;
	stroke-dashoffset: 130;
	animation: brush_130 0.5s linear forwards 5.7s;
}

.txt_8 {
	stroke-dasharray: 460;
	stroke-dashoffset: 460;
	animation: brush_460 0.5s linear forwards 6s;
}

.txt_9 {
	stroke-dasharray: 300;
	stroke-dashoffset: 300;
	animation: brush_300 0.5s linear forwards 6.3s;
}

.txt_10 {
	stroke-dasharray: 10;
	stroke-dashoffset: 10;
	animation: brush_10 0.5s linear forwards 6.5s;
}

.txt_11 {
	stroke-dasharray: 100;
	stroke-dashoffset: 100;
	animation: brush_100 0.5s linear forwards 6.7s;
}

.txt_12 {
	stroke-dasharray: 10;
	stroke-dashoffset: 10;
	animation: brush_10 0.5s linear forwards 7s;
}

.txt_13 {
	stroke-dasharray: 150;
	stroke-dashoffset: 150;
	animation: brush_150 0.5s linear forwards 7.3s;
}

.txt_14 {
	stroke-dasharray: 310;
	stroke-dashoffset: 310;
	animation: brush_310 0.5s linear forwards 7.6s;
}

.txt_15 {
	stroke-dasharray: 160;
	stroke-dashoffset: 160;
	animation: brush_160 0.5s linear forwards 8s;
}

.txt_16 {
	stroke-dasharray: 80;
	stroke-dashoffset: -80;
	animation: brush_-80 0.5s linear forwards 8.3s;
}

.txt_17 {
	stroke-dasharray: 50;
	stroke-dashoffset: 50;
	animation: brush_50 0.5s linear forwards 8.6s;
}

.txt_18 {
	stroke-dasharray: 70;
	stroke-dashoffset: 70;
	animation: brush_70 0.5s linear forwards 9s;
}

@keyframes brush_-80 {
	0% {
		stroke-dashoffset: -80;
	}

	20% {
		stroke-dashoffset: -80;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_70 {
	0% {
		stroke-dashoffset: 70;
	}

	20% {
		stroke-dashoffset: 70;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_310 {
	0% {
		stroke-dashoffset: 310;
	}

	20% {
		stroke-dashoffset: 310;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_150 {
	0% {
		stroke-dashoffset: 150;
	}

	20% {
		stroke-dashoffset: 150;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_100 {
	0% {
		stroke-dashoffset: 100;
	}

	20% {
		stroke-dashoffset: 100;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_50 {
	0% {
		stroke-dashoffset: 50;
	}

	20% {
		stroke-dashoffset: 50;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_10 {
	0% {
		stroke-dashoffset: 10;
	}

	20% {
		stroke-dashoffset: 10;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_300 {
	0% {
		stroke-dashoffset: 300;
	}

	20% {
		stroke-dashoffset: 300;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_130 {
	0% {
		stroke-dashoffset: 130;
	}

	20% {
		stroke-dashoffset: 130;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_110 {
	0% {
		stroke-dashoffset: 110;
	}

	20% {
		stroke-dashoffset: 110;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_230 {
	0% {
		stroke-dashoffset: 230;
	}

	20% {
		stroke-dashoffset: 230;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_-160 {
	0% {
		stroke-dashoffset: -160;
	}

	20% {
		stroke-dashoffset: -160;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_160 {
	0% {
		stroke-dashoffset: 160;
	}

	20% {
		stroke-dashoffset: 160;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_-310 {
	0% {
		stroke-dashoffset: -310;
	}

	20% {
		stroke-dashoffset: -310;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes brush_460 {
	0% {
		stroke-dashoffset: 460;
	}

	20% {
		stroke-dashoffset: 460;
	}

	80% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
	}
}
