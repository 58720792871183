._pd_0 {
	padding: 0;
}

._pd_100_0 {
	padding: 100px 0;
}

._pd_80_0 {
	padding: 80px 0;
}

._pd_120_0 {
	padding: 120px 0;
}

@media screen and (max-width: 991px) {
	.-hide-mob {
		display: none;
	}
}

@media screen and (max-width: 991px) {
	._pd_sm_60_0 {
		padding: 60px 0;
	}
}

@media screen and (min-width: 992px) {
	.border-r-pc {
		border-right: 1px solid rgba(202, 202, 202, 0.28);
	}
}

._mg_0 {
	margin: 0;
}

.img-center {
	margin: 0 auto;
}

.title-section {
	span {
		display: block;
		margin-bottom: 10px;
		color: $main_color;
	}

	h1,
	h2 {
		font-size: 32px;
		line-height: 38px;
	}

	margin-bottom: 40px;

	@media screen and (max-width: 991px) {
		margin-bottom: 20px;
	}
}

a.btn_view_all {

	outline: none;
	color: $main_color;
	text-decoration: none;
	margin-top: 10px;
	display: block;
	position: relative;
	text-align: right;
	letter-spacing: 3px;
	color: $content_color;

	&::before {

		left: 0;
		content: '';
		top: 16px;
		position: absolute;
		height: 1px;
		width: 50px;
		background: $main_color;
	}
}

//-- START :: Font Style
._upperCase {
	text-transform: uppercase !important;
}

._italic {
	font-style: italic !important;
}

//-- END ::  Font Style

._cursor_next {
	cursor: url(../images/next.png), auto;
}

._cursor_prev {
	cursor: url(../images/prev.png), auto;
}


.slick-dots {

	bottom: -45px;

	li button:before {
		display: inline-block;
		font-size: 40px;
	}

}

//
//.slick-dots {
//	text-align: center;
//
//	> li {
//		display: inline-block;
//		margin: 5px;
//		border-radius: 50%;
//	}
//}
