@import "fonts";
@import "variables";
@import "mixins";
@import "component";
//header
// @import "preload";
@import 'navbar';
@import "header";
//content
@import "content";
@import 'helper';
@import 'homepage';
//footer
@import "footer";
//@import "customflexslider";
@import 'svg';
// page
@import "page/header";
@import "page/contact";
@import "page/room";
@import "page/facilities";
@import "page/reservation";

