.body_page {
	.breadcrumb {
		background-color: transparent;
		margin-bottom: 0;

		.breadcrumb-item {
			font-size: 0.9em;
			font-family: $titlefonts;
			color: $maincolor;
			letter-spacing: 7px;

			a {
				color: $maincolor;
				font-family: $titlefonts;
			}

			& + .breadcrumb-item {
				&::before {
					content: '|';
				}
			}
		}
	}
}

.hero_page {
	height: 600px;
	position: relative;
	background-size: cover;
	background-position: center 80%;

	@media (min-width: 768px) and (max-width: 991px) {
		height: 400px;
		margin-top: 70px;
	}

	@media (max-width: 767px) {
		margin-top: 70px;
		height: 250px;
	}

	.title_page {
		position: absolute;
		top: 57%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		text-align: center;

		@media (max-width: 767px) {
			font-size: 0.6em;
			width: 80%;
			margin: 0 auto;

			.breadcrumb {
				padding: 0;
			}
		}

		@media (max-width: 375px) {
			font-size: 0.5em;
		}

		@media (max-width: 320px) {
			font-size: 0.4em;
		}

		.title_name {
			font-size: 3em;
			color: $fontbodylight;
			text-shadow: -22px 17px 10px rgba($maincolor, 0.16);
			font-family: $titlefonts;
		}

		.breadcrumb {
			display: inline-block;
			margin-top: 20px;

			li {
				font-family: $titlefonts;
				color: $maincolor;
				font-size: 0.9em;
				font-weight: lighter;
				letter-spacing: 7px;
			}

			a {
				font-family: $designfonts;
				color: $maincolor;
			}
		}

		.list-inline-item {
			font-family: $titlefonts;
			color: $maincolor;
			font-size: 1.2em;
			font-weight: lighter;
			letter-spacing: 7px;
		}
	}
}

.tab_title_page {
	padding: 70px 0 0 0;
	text-align: center;

	.title_component {
		text-align: center;
		margin-bottom: 20px;

		.name {
			position: relative;

			&:after {
				margin: 35px auto 0;
			}
		}
	}
}
 
.body_page {
	.bht_footer {
		background: #fff;
	}
}


.content_page {
	padding-top: 50px;
	padding-bottom: 70px;

	&.content_contact {
		padding-bottom: 0;
	}
}

.final-tiles-gallery .ftg-filters a.selected,
.final-tiles-gallery .ftg-filters a:hover {
	color: #fff;
	border-color: $maincolor;
	background: $maincolor;
	text-decoration: none;
}

.list_download {
	max-width: 650px;
	margin: 0 auto;
}

.list_download .tab_linkdownload {
	margin-bottom: 10px;
	background: #f4f5f0;
	padding: 15px 75px 15px 30px;
	width: 100%;
	display: block;
	cursor: pointer;
	position: relative;
}

.list_download .tab_linkdownload span {
	color: $maincolor;
	font-weight: 600;
}

.list_download .tab_linkdownload i {
	margin-left: 30px;
	font-size: 20px;
	color: $maincolor;
}

.list_download .tab_linkdownload .icon_download {
	position: absolute;
	right: 0;
	top: 0;
	width: 50px;
	background-color: $maincolor;
	height: 100%;
	background-image: url('../images/iconload.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 14px
}

.career {
	margin-bottom: 70px;
	font-weight: lighter;

	.name_title {
		font-size: 1.5em;
		color: $secondcolor;
		font-family: $titlefonts;
		margin-bottom: 30px;
		font-weight: lighter;
	}
}

.link_career {
	a {
		color: #888 !important;
		text-decoration: none;
		position: relative;

	}
}
